import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiButton, EuiSpacer } from '@elastic/eui';
import YoutubeVideo from "components/Internet_Access/Port_Forwarding/Vodafone_Easybox/YoutubeVideoCardForwarding";
import NavButtons from 'components/Internet_Access/Port_Forwarding/NavButtons';
import ForumBox from 'components/Internet_Access/Port_Forwarding/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Port Forwarding - Vodafone EasyBox",
  "path": "/Internet_Access/Port_Forwarding/Vodafone_Easybox/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Connect to your Camera through the Internet",
  "image": "./IA_SearchThumb_Portforwarding_Easybox.png",
  "social": "/images/Search/IA_SearchThumb_Portforwarding_Easybox.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_IA-Port-Forwarding_white.webp",
  "chapter": "Remote Access"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Port Forwarding - Vodafone EasyBox' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Connect to your Camera through the Internet' image='/images/Search/IA_SearchThumb_Portforwarding_Easybox.png' twitter='/images/Search/IA_SearchThumb_Portforwarding_Easybox.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Fernzugriff/Port_Weiterleitung/Vodafone_Easybox/' locationFR='/fr/Internet_Access/Port_Forwarding/Vodafone_Easybox/' crumbLabel="Vodafone EasyBox" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "port-forwarding",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#port-forwarding",
        "aria-label": "port forwarding permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Port Forwarding`}</h1>
    <h2 {...{
      "id": "vodafone-easybox",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#vodafone-easybox",
        "aria-label": "vodafone easybox permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Vodafone EasyBox`}</h2>
    <p>{`This is the 2nd step of the Port Forwarding, click `}<a parentName="p" {...{
        "href": "/en/Internet_Access/The_DDNS_Service/"
      }}>{`here`}</a>{` to get to Step I.`}</p>
    <YoutubeVideo mdxType="YoutubeVideo" />
    <h3 {...{
      "id": "step-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-1",
        "aria-label": "step 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 1`}</h3>
    <p>{`To access the user interface of your router, open the following address with your internet browser `}<em parentName="p">{`192.168.2.1`}</em>{`. The `}<strong parentName="p">{`default login`}</strong>{` is username = root or admin and password = 123456. Navigate to the `}<strong parentName="p">{`Port Mapping`}</strong>{` in the Data tab and click on the `}<strong parentName="p">{`Edit`}</strong>{` symbol under Configure.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "680px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2b358c2b104d1a1981515761eda26f47/7bf67/Easybox_01en.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.826086956521735%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHSomgP/8QAGBAAAwEBAAAAAAAAAAAAAAAAAAEDEwL/2gAIAQEAAQUCUeGYzMZn/8QAGBEAAwEBAAAAAAAAAAAAAAAAABJRAQL/2gAIAQMBAT8BbqjbT//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/AYj/xAAWEAEBAQAAAAAAAAAAAAAAAAAAMqH/2gAIAQEABj8ChGIf/8QAGBAAAwEBAAAAAAAAAAAAAAAAAAFhEZH/2gAIAQEAAT8hQa1JRHw//9oADAMBAAIAAwAAABB77//EABYRAAMAAAAAAAAAAAAAAAAAAABhkf/aAAgBAwEBPxByj1P/xAAWEQADAAAAAAAAAAAAAAAAAAAAEVH/2gAIAQIBAT8QSCQ//8QAGhAAAgIDAAAAAAAAAAAAAAAAAAERIUGBwf/aAAgBAQABPxBC0qLOITJph5U//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2b358c2b104d1a1981515761eda26f47/e4706/Easybox_01en.avif 230w", "/en/static/2b358c2b104d1a1981515761eda26f47/d1af7/Easybox_01en.avif 460w", "/en/static/2b358c2b104d1a1981515761eda26f47/e3b5b/Easybox_01en.avif 680w"],
              "sizes": "(max-width: 680px) 100vw, 680px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2b358c2b104d1a1981515761eda26f47/a0b58/Easybox_01en.webp 230w", "/en/static/2b358c2b104d1a1981515761eda26f47/bc10c/Easybox_01en.webp 460w", "/en/static/2b358c2b104d1a1981515761eda26f47/35037/Easybox_01en.webp 680w"],
              "sizes": "(max-width: 680px) 100vw, 680px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2b358c2b104d1a1981515761eda26f47/e83b4/Easybox_01en.jpg 230w", "/en/static/2b358c2b104d1a1981515761eda26f47/e41a8/Easybox_01en.jpg 460w", "/en/static/2b358c2b104d1a1981515761eda26f47/7bf67/Easybox_01en.jpg 680w"],
              "sizes": "(max-width: 680px) 100vw, 680px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2b358c2b104d1a1981515761eda26f47/7bf67/Easybox_01en.jpg",
              "alt": "Portforwarding Vodafone EasyBox",
              "title": "Portforwarding Vodafone EasyBox",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-2",
        "aria-label": "step 2 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 2`}</h3>
    <p>{`Type your camera´s IP address into the field LAN IP. Set both the `}<strong parentName="p">{`LAN`}</strong>{` and `}<strong parentName="p">{`Public Port`}</strong>{` to the port of your camera. For your first camera both ports are `}<em parentName="p">{`8081`}</em>{`, for your second camera `}<em parentName="p">{`8082`}</em>{` etc. Click the `}<strong parentName="p">{`Enter`}</strong>{`-Symbol under Configure to save the service.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "680px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bfba51a50c25160da1395070a3bc6c09/7bf67/Easybox_02en.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAdKyUD//xAAXEAADAQAAAAAAAAAAAAAAAAAAAhMB/9oACAEBAAEFAprpJCaH/8QAFhEAAwAAAAAAAAAAAAAAAAAAAAES/9oACAEDAQE/AaZTP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABcQAAMBAAAAAAAAAAAAAAAAAAABMzL/2gAIAQEABj8CwiZNH//EABoQAAICAwAAAAAAAAAAAAAAAAABMWGBkfH/2gAIAQEAAT8hU52ChpHMR//aAAwDAQACAAMAAAAQj/8A/8QAFhEBAQEAAAAAAAAAAAAAAAAAAGEB/9oACAEDAQE/EK6q/8QAFhEAAwAAAAAAAAAAAAAAAAAAAAER/9oACAECAQE/EIiI/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAERMSFB/9oACAEBAAE/EFnDWxDMZPtRH6H/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bfba51a50c25160da1395070a3bc6c09/e4706/Easybox_02en.avif 230w", "/en/static/bfba51a50c25160da1395070a3bc6c09/d1af7/Easybox_02en.avif 460w", "/en/static/bfba51a50c25160da1395070a3bc6c09/e3b5b/Easybox_02en.avif 680w"],
              "sizes": "(max-width: 680px) 100vw, 680px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bfba51a50c25160da1395070a3bc6c09/a0b58/Easybox_02en.webp 230w", "/en/static/bfba51a50c25160da1395070a3bc6c09/bc10c/Easybox_02en.webp 460w", "/en/static/bfba51a50c25160da1395070a3bc6c09/35037/Easybox_02en.webp 680w"],
              "sizes": "(max-width: 680px) 100vw, 680px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bfba51a50c25160da1395070a3bc6c09/e83b4/Easybox_02en.jpg 230w", "/en/static/bfba51a50c25160da1395070a3bc6c09/e41a8/Easybox_02en.jpg 460w", "/en/static/bfba51a50c25160da1395070a3bc6c09/7bf67/Easybox_02en.jpg 680w"],
              "sizes": "(max-width: 680px) 100vw, 680px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bfba51a50c25160da1395070a3bc6c09/7bf67/Easybox_02en.jpg",
              "alt": "Portforwarding Vodafone EasyBox",
              "title": "Portforwarding Vodafone EasyBox",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The service is now active.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "680px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7d0873644ad1d022d8a15bd7260e1013/7bf67/Easybox_03en.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQAC/9oADAMBAAIQAxAAAAHRsNBH/8QAGBAAAwEBAAAAAAAAAAAAAAAAAAITAQP/2gAIAQEAAQUCkmkUJcz/xAAXEQADAQAAAAAAAAAAAAAAAAAAARJR/9oACAEDAQE/AaelPT//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwGI/8QAFxABAQEBAAAAAAAAAAAAAAAAADIBof/aAAgBAQAGPwKMRxD/xAAZEAADAAMAAAAAAAAAAAAAAAAAAWExgZH/2gAIAQEAAT8hWY7RGJeH/9oADAMBAAIAAwAAABD7/wD/xAAWEQEBAQAAAAAAAAAAAAAAAAAAYZH/2gAIAQMBAT8Qu1c//8QAFhEAAwAAAAAAAAAAAAAAAAAAABFR/9oACAECAQE/EEgkP//EABsQAAIBBQAAAAAAAAAAAAAAAAABMREhUXGx/9oACAEBAAE/EFCxDoIeQnt7U//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7d0873644ad1d022d8a15bd7260e1013/e4706/Easybox_03en.avif 230w", "/en/static/7d0873644ad1d022d8a15bd7260e1013/d1af7/Easybox_03en.avif 460w", "/en/static/7d0873644ad1d022d8a15bd7260e1013/e3b5b/Easybox_03en.avif 680w"],
              "sizes": "(max-width: 680px) 100vw, 680px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7d0873644ad1d022d8a15bd7260e1013/a0b58/Easybox_03en.webp 230w", "/en/static/7d0873644ad1d022d8a15bd7260e1013/bc10c/Easybox_03en.webp 460w", "/en/static/7d0873644ad1d022d8a15bd7260e1013/35037/Easybox_03en.webp 680w"],
              "sizes": "(max-width: 680px) 100vw, 680px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7d0873644ad1d022d8a15bd7260e1013/e83b4/Easybox_03en.jpg 230w", "/en/static/7d0873644ad1d022d8a15bd7260e1013/e41a8/Easybox_03en.jpg 460w", "/en/static/7d0873644ad1d022d8a15bd7260e1013/7bf67/Easybox_03en.jpg 680w"],
              "sizes": "(max-width: 680px) 100vw, 680px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7d0873644ad1d022d8a15bd7260e1013/7bf67/Easybox_03en.jpg",
              "alt": "Portforwarding Vodafone EasyBox",
              "title": "Portforwarding Vodafone EasyBox",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-3",
        "aria-label": "step 3 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 3`}</h3>
    <p>{`To be able to access the Flash Video as well, there has to be set a second Port Forwarding. Therefore, just follow the manual of this step, with the only difference to set the Flash (RTMP) Port instead. So for your first camera all ports are set to 1935, for your second camera it´s 1936, etc.`}</p>
    <p>{`Now all Port Forwardings for your camera are set in the router. You can now continue with the next step:`}</p>
    <h2 {...{
      "id": "step-iii-the-cameras-ddns-address-and-the-remote-access",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#step-iii-the-cameras-ddns-address-and-the-remote-access",
        "aria-label": "step iii the cameras ddns address and the remote access permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step III: The Camera´s DDNS-Address and the Remote-Access`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <Link to="/Internet_Access/Mobile_Access/" mdxType="Link"><EuiButton fill mdxType="EuiButton">Step III</EuiButton></Link>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      